<template>
    <b-card>
        <Form ref="createRedirect" :submit="submit"/>
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions } from "vuex";

export default {
    name: "CreateRedirect",

    components: {
        Form
    },

    methods: {
        ...mapActions({
            createRedirect: 'redirect/create'
        }),
        submit() {
            const refRedirect = this.$refs.createRedirect
            refRedirect.errMessage = null
            refRedirect.loading = true

            const data = JSON.parse(JSON.stringify(refRedirect.redirect))
            this.createRedirect(data).then(() => {
                // this.$router.push({ name: 'redirects' })
                refRedirect.loading = false

                this.$swal({
                    icon: 'success',
                    title: this.$t(`Created!`),
                    text: this.$t('Your file has been created.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
            }).catch(response => {
                if (response.errors) {
                    refRedirect.$refs.form.setErrors(response.errors)
                }
                refRedirect.loading = false

                refRedirect.errMessage = response.message
            })
        }
    }
}
</script>
